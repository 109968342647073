/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 hello_kitty.glb --transform
Author: welbot (https://sketchfab.com/welbot)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/hello-kitty-ebaf7a375aa94d53952fa9705cec0851
Title: Hello Kitty
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export default function HelloKitty(props) {
  const { nodes, materials } = useGLTF("/hello_kitty-transformed.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.02}>
        <mesh
          geometry={nodes.Object_2.geometry}
          material={materials["Scene_-_Root"]}
        />
        <mesh
          geometry={nodes.Object_3.geometry}
          material={materials["Scene_-_Root"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/hello_kitty-transformed.glb");
